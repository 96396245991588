.headerTop {
    background: var(--white-color);
    position: sticky;
    top: 0;
    z-index: 20;
}
.menuWrapper {
    display: flex;
    align-items: center;
    width: 100%;
}


.headerTop.active {
    background: var(--white-color);
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.27);
}
.logoBox {
    flex-shrink: 0
}
.logoBox,
.logoBox a {
    line-height: 0px;
}
.logoBox a {
    display: block;
}

.showMenu {
    position: fixed;
    height: calc(100vh - 50px);
    margin: 0px;
    top: 50px;
    width: 100%;
    background: #fff;
    left: 0;
    overflow: auto;
    flex-flow: column nowrap;
}

.navBarBtn {
    display: none;
    margin-left: auto;
    border: 0px;
    background: none;
    outline: none;
    align-items: center;
    gap: 10px;
}


.navBarArr {
    background: #333;
    display: block;
    height: 2px;
    position: relative;
    transition: background .2s ease-out;
    width: 18px;
}

.navBarArr:before,
.navBarArr:after {
    background: #333;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
}

.navBarArr:before {
    top: 5px;
}

.navBarArr:after {
    top: -5px;
}

.mainMenu {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.mainMenu > ul {
    display: flex;
}
.mainMenu > ul > li {
    font-family: var(--poppins-semi-medium);
}
.mainMenu li {
    font-size: 16px;
    line-height: 46px;
    font-weight: 500;
    color: var(--secondry-color);
    cursor: pointer;
    position: relative;
}

.mainMenu li a {
    color: var(--secondry-color);
}

.mainMenu li.dropdown > span {
    position: relative;
    padding-right: 25px;
}
.mainMenu li.dropdown > ul {
    display: none;
}
.mainMenu li.dropdown > ul > li {
    font-family: var(--poppins-regular);
}
.mainMenu li.dropdown > ul > li > a {
    display: block;
    padding: 0 20px;
    background-color: #f7f7b2;
}

.navBarBtnActive .navBarArr {
    background: transparent;
}

.navBarBtnActive .navBarArr:before {
    transform: rotate(-45deg);
}

.navBarBtnActive .navBarArr:after {
    transform: rotate(45deg);
}

.navBarBtnActive .navBarArr:before,
.navBarBtnActive .navBarArr:after {
    top: 0;
}

.header-one {
    position: relative;
    width: 100%;
    padding: 15px 0;
    background-color: var(--white-color);
    z-index: 10;
}

.header-one .headerOne-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}
.header-one .headerOne-logo a {
    line-height: 0px;
    display: block;
}
.header-one.active {
    position: sticky;
    left: 0;
    top: 0;
}

@media screen and (max-width:991.98px) {
    .headerTop {
        padding: 10px 0;
    }

    .mainMenu {
        display: none;
    }
    .logoBox,
    .logoBox svg {
        width: 180px;
        height: 30px;
    }
    .mainMenu > ul {
        flex-flow: column nowrap;
        padding: 0 20px;
        width: 100%;
    }
    .mainMenu > ul li {
        border-bottom: 1px solid #ddd; 
    }
    
    .mainMenu > ul > li {
        padding: 5px 0;
    }
    .mainMenu > ul li:last-child {
        border-bottom: 0px;
    }
    .mainMenu li.dropdown > span {
        width: 100%;
        display: block;
    }
    .mainMenu li.dropdown > span svg {
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
    }
    .mainMenu li.dropdown > ul {
        display: block;
    }
    .showMenu {
        display: flex;
        justify-content: flex-start;
    }

    .menuRight {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        background: #fafafa;
        padding: 15px;
        width: 100%;
    }
    .menuRight a {
        width: 100%;
    }
    .navBarBtn {
        display: flex;
    }
}
@media screen and (max-width: 991.98px) {
    .headerOne-logo,
    .headerOne-logo svg {
        width: 242px;
        height: 40px;
    }
}
@media screen and (max-width: 575.98px) {
    .headerOne-logo,
    .headerOne-logo svg {
        width: 160px;
        height: 25px;
    }
    .headerOne-menu {
        display: none;
    }
}
@media screen and (min-width: 992px) {
    .containerFlex {
        align-items: center;
    }
    .mainMenu {
        margin-left: 50px;
    }
    .logoBox,
    .logoBox svg {
        width: 242px;
        height: 40px;
    }
    .mainMenu > ul {
        grid-gap: 20px
    }
    .mainMenu li.dropdown > ul {
        padding: 12px 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        width: 270px;
        left: -20px;
        opacity: 0;
        animation: showDropDown 0.3s linear;
        box-shadow: 0 7px 6px rgba(0, 0, 0, 0.1);
        top: calc(100% - 12px);
    }
    .mainMenu li.dropdown > span svg{
        position: absolute;
        top: 2px;
        right: 0;
        transform-origin: center;
        transition: all 0.3s linear;
        line-height: 0px;
        transform: rotate(0deg);
        transform-origin: center;
    }
    .mainMenu li.dropdown > ul > li > a:hover {
        background-color: var(--primary-color);
        color: #000;
    }
    .mainMenu li.dropdown:hover > ul{
        display: block;
        opacity: 1;
        animation: showDropDown 0.3s linear;
    }
    .mainMenu > ul > li {
        padding: 15px 0;
    }
    .mainMenu li.dropdown:hover > span svg {
        transform: rotate(180deg);
        transition: all 0.3s linear;
        transform-origin: center;
    }
    @keyframes showDropDown {
        0% {
            transform: translateY(20px);
            opacity: 0;
        } 100% {
            transform: translateY(0px);
            opacity: 1;
        }
    }
    .mainMenu li.dropdown > ul {
        position: absolute;
        background: #fff;
    }
    .menuRight {
        margin-left: auto;
        display: flex;
        grid-gap: 15px
    }

    .menuRight1 {
        margin-left: initial;
    }
    .menuRight {
        display: flex;
        flex-flow: row nowrap;
        gap: 20px;
        align-items: center;
        padding: 10px;
    }
    
    .header-one .headerOne-logo svg {
        width: 242px;
        height: 40px;
    }
}
@media screen and (min-width: 1200px) {
    .mainMenu > ul {
        grid-gap: 40px;
    }
}
@media (min-width: 992px) and (max-width: 1200px) {
    .mainMenu button {
      padding: 2px 10px !important;
      font-size: 12px !important;
    }

    .mainMenu li {
        font-size: 14px !important;
    }
    .mainMenu > ul {
        grid-gap: 13px;
    }
   
  }

.menuWrapper .mainMenu ul li.dropdown ul li a {
    border-radius: 5px !important;
    /* background: #ddd; */
    display: flex;
    align-items: center;
}
.menuWrapper .mainMenu ul li.dropdown ul li {
    margin-bottom: 4px;
}

.menuWrapper .mainMenu ul li.dropdown ul{
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px !important;
}

.menuWrapper .mainMenu ul li.dropdown ul li a svg {
    width: 20px;
    height: 20px;
    margin: 0 5px 5px 0;
    color: #000000;
}
.menuWrapper .mainMenu ul li.dropdown ul li img{
    width: 20px;
    margin-right: 5px;
}